<!--我感兴趣愿意免费沟通的项目-->
<template>
    <div class="container">
        <BreadcrumbNav :page-name="$t('vipRecommend.detailsOfMyProjects')"/>
        <div class="main">
            <div class="text-group1">
                <h1>{{ $t("myProject.itemsIRecommendToOtherMembers") }}</h1>
                <!-- <h2>{{ $t("myProject.recommendedPerson") }}</h2> -->
                <div style="display: flex; align-items: center; margin-top: 19px">
                    <img :src="details_data.recipient_avatar" alt=""
                         style="width: 49px; height: 49px; border-radius: 50%"/>
                    <div style="margin-left: 12px">
                        <h2>{{ details_data.recipient_name }}</h2>
                        <h2 style="color: #949494; margin-top: 6px">{{$t("myProject.recommendedPerson")}}</h2>
                    </div>
                </div>
            </div>
            <div class="text-group2">
                <h1>{{ details_data.project_name }}</h1>
                <div class="text-group2-content">
                    <span class="publish-date">{{ details_data.project_intro }}</span>
                </div>
            </div>
            <div class="people-wrap">
                <img :src="details_data.talent_avatar" alt="" class="head-icon fl"/>
                <div class="people-info-text fl">
                    <h1 class="over">{{ details_data.talent_name }}</h1>
                    <h2 class="over">
                        {{ details_data.company_name }} · {{ details_data.position }}
                    </h2>
                </div>

                <div class="btn-group fl">
                    <CustomButton
                            @click.native="$router.push({path: '/user/index',query:{userType:2, talentId:details_data.talent_id, }})"
                            class="fl" type="black">
                        {{ $t("project.buyNow") }}<!--立即购买-->
                    </CustomButton>
                    <CustomButton @click.native="attention($event,2)" class="fl" style="margin-left: 13px" type="white"
                                  v-if="details_data.followed == 1">
                        {{ $t("project.followed") }}<!--已关注-->
                    </CustomButton>
                    <CustomButton @click.native="attention($event,1)" class="fl" style="margin-left: 13px" type="black"
                                  v-if="details_data.followed == 0">
                        {{ $t("project.follow") }}<!--关注-->
                    </CustomButton>
                </div>
            </div>
            <div class="project-rate">
                <h1>{{ $t("participateProjectProgress.participateProjectProgress") }}</h1>
                <table>
                    <thead>
                    <tr>
                        <td>{{ $t("project.pTime") }}<!--时间--></td>
                        <td>{{ $t("myProject.speedOfProgress") }}<!--进度--></td>
                        <td>{{ $t("project.pReason") }}<!--原因--></td>
                        <td></td>
                    </tr>
                    </thead>
                    <!-- 参与项目的进度 -->
                    <tbody>
                    <tr :key="index" v-for="(item, index) in details_data.progressList">
                        <td>{{ item.create_time}}</td>
                        <td>{{ item.progress_name }}</td>
                        <td v-if="item.message_content">{{ item.message_content }}</td>
                        <td>-</td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="project-detail">
                <!-- 翻译：项目详情-->
                <h1>{{ $t("vipRecommend.projectDetail") }}</h1>
                <div class="rich-text">
                    <p v-html="details_data.details"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import {myJoinprojectDetails} from "@/api/projectAbout";
    import {followTalent} from "@/api/memberApi";

    export default {
        name: "index",
        components: {
            BreadcrumbNav,
            CustomButton,
        },
        data() {
            return {
                type: 2, //0:未选择、1：同意、2：拒绝、3：预约时间、4：发布者已取消、5：您已取消
                stateObject: {
                    isOnly: true,
                    communicateType: 0,
                },
                delivery_data: {},
                details_data: {},
            };
        },
        mounted() {
            this.delivery_data = this.$route.query;
            this.get_projectDetails();
        },
        methods: {
            //关注
            attention(event, type) {
                if (event.target.disabled) {
                    // 点击太频繁了
                    this.$message.warning(this.$t('myProject.clickFrequentMsg'))//点击太频繁了

                }
                let formData = {
                    operateType: type,
                    talentId: this.details_data.talent_id,
                    talentName: this.details_data.talent_name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            this.details_data.followed = 1;
                            this.$message.success(this.$t('myProject.followMsg.followSuccess'))//关注成功
                        } else {
                            this.details_data.followed = 0;
                            this.$message.success(this.$t('myProject.followMsg.unfollowSuccess'))//取消关注成功
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            //   项目详情
            get_projectDetails() {
                let formData = {
                    token: localStorage.getItem("token"),
                    mainId: this.delivery_data.project_Id,
                    dataType: this.delivery_data.pd_num,
                    interviewId: this.delivery_data.interviewId,

                };
                myJoinprojectDetails(formData).then((res) => {
                    this.details_data = res.data;
                });
            },
            getMsgFormSon(data) {
                console.log(data);
            },
        },
    };
</script>

<style scoped>
    .container {
        background-color: #ffffff;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        margin: 0 auto;
    }

    .text-group1 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .text-group1 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .text-group1 > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-top: 8px;
    }

    .text-group2 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 30px;
    }

    .text-group2-content {
        margin-top: 8px;
    }

    .publish-date {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .brief-intro {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-left: 16px;
    }

    .text-group2 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .people-wrap {
        padding: 38px 0;
        overflow: hidden;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        margin-left: 4px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    .reason {
        margin-top: 65px;
    }

    .reason > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .reason > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .is-btn-group {
        margin-top: 38px;
        overflow: hidden;
    }

    .project-detail {
        margin-top: 72px;
    }

    .project-detail > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .project-detail .rich-text {
        margin-top: 42px;
    }

    .project-rate > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    table {
        width: 100%;
    }

    table td {
        text-align: center;
        vertical-align: middle;
    }

    table > thead > tr {
        height: 47px;
        background: #fafafa;
    }

    table > thead > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
    }

    table > thead > tr > td:first-child {
        width: 172px;
    }

    table > thead > tr > td:nth-child(2) {
        width: 256px;
    }

    table > thead > tr > td:nth-child(3) {
        width: 92px;
    }

    table > tbody > tr {
        height: 55px;
    }

    table > tbody > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }
</style>
